import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

import { StaticImage } from "gatsby-plugin-image";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import {
  navLinks,
  navLinkItem,
  navLinkText,
  navContainer,
  bars,
  logo,
} from "./layout.module.css";
import {
  careerTestLinks,
  hiringLinks,
  resumeLinks,
} from "../linkData/navLinks";
import NavDropDown from "./NavDropDown";

function NavBar() {
  const width = () => {
    if (typeof window !== `undefined`) {
      return window.innerWidth;
    }
  };
  const [showNav, setShowNav] = useState(false);
  const [screenWidth, setScreenWidth] = useState(width);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(width);
    };
    if (typeof window !== `undefined`)
      window.addEventListener("resize", changeWidth);
    return () => {
      if (typeof window !== `undefined`)
        window.removeEventListener("resize", changeWidth);
    };
  }, []);
  const barClick = () => {
    setShowNav(!showNav);
  };
  return (
    <>
      {screenWidth <= 640 && (
        <FontAwesomeIcon icon={faBars} className={bars} onClick={barClick} />
      )}
      <div className={navContainer}>
        <div className={logo}>
          <StaticImage
            alt="logo"
            src="../images/cc_logo.png"
            height={150}
            placeholder="none"
            layout="fixed"
          />
        </div>

        <div>
          <nav>
            {(showNav || screenWidth > 640) && (
              <ul className={navLinks}>
                <li className={navLinkItem}>
                  <Link to="/" className={navLinkText}>
                    Home
                  </Link>
                </li>
                <li className={navLinkItem}>
                  <Link to="" className={navLinkText}>
                    CARRER&nbsp;RESOURCES
                  </Link>
                </li>
                <li className={navLinkItem}>
                  <Link to="" className={navLinkText}>
                    PODCAST
                  </Link>
                </li>

                <NavDropDown
                  title="resumes"
                  link={resumeLinks}
                  screenWidth={screenWidth}
                />

                <NavDropDown
                  title="career testing"
                  link={careerTestLinks}
                  screenWidth={screenWidth}
                />
                <NavDropDown
                  title="Hiring"
                  link={hiringLinks}
                  screenWidth={screenWidth}
                />
              </ul>
            )}
          </nav>
        </div>
      </div>
    </>
  );
}

export default NavBar;
