import React from "react";
import Layout from "../components/layout";
import RightBody from "../components/RightBody";
import { body } from "../components/layout.module.css";
import LeftBody from "../components/LeftBody";
import LowerBody from "../components/LowerBody";

function index() {
  return (
    <Layout pageTitle="Home Page">
      <div className={body}>
        <div>
          <RightBody />
          <LowerBody />
        </div>
        <LeftBody />
      </div>
    </Layout>
  );
}

export default index;
