import React, { useState } from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import {
  navLinkItem,
  navLinkText,
  dropDown,
  dropbtn,
  dropDownContent,
  showContent,
} from "./layout.module.css";

function NavDropDown({ title, link, screenWidth }) {
  const [showLinks, setShowLinks] = useState(false);

  const iconClick = () => {
    setShowLinks(!showLinks);
  };
  return (
    <div className={showContent} style={{ textAlign: "center" }}>
      <li
        className={navLinkItem}
        style={{ position: "relative", right: 8, userSelect: "none" }}
        onClick={iconClick}
      >
        {screenWidth <= 640 && (
          <>
            {!showLinks && (
              <FontAwesomeIcon
                icon={faPlus}
                style={{
                  padding: 2,
                  paddingRight: 4,
                  fontSize: 10,
                }}
              />
            )}
            {showLinks && (
              <FontAwesomeIcon
                icon={faMinus}
                size="xs"
                style={{
                  padding: 2,
                  paddingRight: 4,

                  fontSize: 8,
                }}
              />
            )}
          </>
        )}
        <span className={dropbtn}>{title}</span>
      </li>

      <div
        className={!showLinks || screenWidth > 640 ? dropDownContent : dropDown}
      >
        {link.map((item) => (
          <li className={navLinkItem}>
            <Link
              to=""
              className={navLinkText}
              style={screenWidth < 640 ? { whiteSpace: "nowrap" } : {}}
            >
              {item.title}
            </Link>
          </li>
        ))}
      </div>
    </div>
  );
}

export default NavDropDown;
