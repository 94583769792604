import React from "react";
// import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import FIcon from "../images/svg/facebook.svg";
import LIcon from "../images/svg/linkedin.svg";
import PIcon from "../images/svg/pinterest.svg";
import Icon from "../images/svg/instagram.svg";
import {
  footerContainer,
  logoContainer,
  breadCrumb,
  footerContent,
  inputIcon,
  inputField,
  searchContainer,
  bottomLinks,
  svg,
} from "./layout.module.css";

function Footer() {
  return (
    <div className={footerContainer}>
      <div className={footerContent}>
        <div className={logoContainer}>
          <a>
            <FIcon className={svg} />
          </a>
          <a>
            <LIcon className={svg} />
          </a>
          <a>
            <PIcon className={svg} />
          </a>
          <a>
            <Icon className={svg} />
          </a>
        </div>
        <div className={searchContainer}>
          <FontAwesomeIcon icon={faMagnifyingGlass} className={inputIcon} />
          <input
            className={inputField}
            type="text"
            placeholder="Search"
            name="search"
          />
        </div>
        <div className={bottomLinks}>
          <ul className={breadCrumb}>
            <li>
              <a href="#">
                <h3>ABOUT</h3>
              </a>
            </li>
            <li>
              <h3>/</h3>
            </li>
            <li>
              <a href="#">
                <h3>CONTRIBUTE</h3>
              </a>
            </li>
            <li>
              <h3>/</h3>
            </li>
            <li>
              <a href="#">
                <h3>CONTACT</h3>
              </a>
            </li>
            <li>
              <h3>/</h3>
            </li>
            <li>
              <a href="#">
                <h3>PRIVACY</h3>
              </a>
            </li>
          </ul>
          <div>
            <a href="#">
              <h3>SUSCRIBE TO CAREER CLOUD RADIO ON ITUNES</h3>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
