import React, { useState } from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookmark,
  faComment,
  faUser,
  faTag,
  faChevronLeft,
  faChevronRight,
  faSquareShareNodes,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";
import {
  lowerBody,
  commentsAndLikes,
  pagination,
  share,
  longText,
  commentsAndLikesIcon,
} from "./layout.module.css";

function LowerBody() {
  const [likeCount, setLikeCount] = useState(2);
  const [isLiked, setIsLiked] = useState(false);

  const handleLike = () => {
    if (isLiked) {
      setLikeCount(likeCount - 1);
      setIsLiked(false);
    }
    if (!isLiked) {
      setLikeCount(likeCount + 1);
      setIsLiked(true);
    }
  };

  return (
    <div className={lowerBody}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "1rem",
          flexWrap: "wrap",
        }}
      >
        <div>
          <div>
            <Link to="">
              <FontAwesomeIcon icon={faUser} className={commentsAndLikesIcon} />{" "}
              CareerCloud Team
            </Link>{" "}
            <Link to="">
              <FontAwesomeIcon
                icon={faComment}
                className={commentsAndLikesIcon}
              />
              Comment
            </Link>
          </div>
          <div>
            <FontAwesomeIcon
              icon={faBookmark}
              className={commentsAndLikesIcon}
            />{" "}
            <Link to="">Your Resume</Link>,<Link to="">Recent</Link>
          </div>
          <div>
            <FontAwesomeIcon icon={faTag} className={commentsAndLikesIcon} />{" "}
            <Link to="">Resume</Link> <Link to="">Resume Writing Services</Link>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "1rem",
            cursor: "pointer",
            userSelect: "none",
          }}
        >
          <div>
            <FontAwesomeIcon
              icon={faHeart}
              style={{ color: isLiked ? "red" : "#cfcfcf " }}
              onClick={handleLike}
            />
            <span> {likeCount} Likes</span>{" "}
          </div>
          <div className={share}>
            {" "}
            <FontAwesomeIcon icon={faSquareShareNodes} />
            <span> Share</span>
          </div>
        </div>
      </div>
      <div className={pagination}>
        <div>
          {" "}
          <Link to="">
            <FontAwesomeIcon
              icon={faChevronLeft}
              style={{ paddingRight: 10 }}
              title="view previous page"
            />{" "}
            <span className={longText}>ZIPRECRUITER VS LINKEDIN </span>
          </Link>{" "}
        </div>

        <div>
          <Link to="">
            <span className={longText}>
              BEST SITES TO SEARCH FOR RESUMES IN..
            </span>{" "}
            <FontAwesomeIcon
              icon={faChevronRight}
              style={{ paddingLeft: 10 }}
              title="view next page"
            />
          </Link>{" "}
        </div>
      </div>
      <div className={commentsAndLikes}>
        <div>
          <h3>COMMENTS(0)</h3>
        </div>
        <div>
          {" "}
          <select>
            <option>Oldest First</option>
            <option>Newest First</option>
            <option selected>Most Liked</option>
            <option>Least Liked</option>
          </select>{" "}
        </div>
      </div>
    </div>
  );
}

export default LowerBody;
