import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { linkButton, ad, bodyLogo } from "./layout.module.css";
import loadable from "@loadable/component";

const VideoEmbeds = loadable(() => import("./VideoEmbeds"));

function RightBody() {
  const data = useStaticQuery(graphql`
    query {
      allMdx {
        nodes {
          frontmatter {
            title
            embeddedImagesLocal {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          body
          id
        }
      }
    }
  `);

  return (
    <>
      <div>
        {data.allMdx.nodes.map((node) => (
          <article key={node.id}>
            <p>
              {" "}
              <MDXRenderer
                video={<VideoEmbeds />}
                linkButton={linkButton}
                localImages={node.frontmatter.embeddedImagesLocal}
                bodyLogo={bodyLogo}
                ad={ad}
              >
                {node.body}
              </MDXRenderer>
            </p>
          </article>
        ))}
      </div>
    </>
  );
}

export default RightBody;
