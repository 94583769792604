import * as React from "react";
import { Helmet } from "react-helmet";
import { container } from "./layout.module.css";

import NavBar from "./Navbar";
import Footer from "./Footer";

const Layout = ({ pageTitle, children }) => {
  return (
    <div className={container}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content="task from Hassan" />

        <title>{pageTitle} | careercloud-clone</title>
      </Helmet>

      {/* <header className={siteTitle}>{data.site.siteMetadata.title}</header> */}
      <NavBar />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
