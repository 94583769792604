export const resumeLinks = [
  {
    title: "best resume wiritng services",
    link: "#",
  },
  {
    title: "best resume builders",
    link: "#",
  },
  {
    title: "Best Executive Resume Writing services",
    link: "#",
  },
  {
    title: "best federal resume writing services",
    link: "#",
  },
  {
    title: "best buzzwords for your resume",
    link: "#",
  },
  {
    title: "best sites to search for resumes",
    link: "#",
  },
  {
    title: "ats resume that will beat the bots",
    link: "#",
  },
  {
    title: "career change resume",
    link: "#",
  },
  {
    title: "how to write the perfect resume",
    link: "#",
  },
  {
    title: "Ats resume test",
    link: "#",
  },
];

export const careerTestLinks = [
  {
    title: "career test",
    link: "#",
  },
  {
    title: "How to pass a pre-employment test",
    link: "#",
  },
  {
    title: "how to pass a typing test",
    link: "#",
  },
  {
    title: "Best personality test",
    link: "#",
  },
];

export const hiringLinks = [
  {
    title: "How to Find Employees",
    link: "#",
  },
  {
    title: "Best Free Job Posting Sites",
    link: "#",
  },
  {
    title: "Best Applicant Tracking software",
    link: "#",
  },
  {
    title: "Best Hr software",
    link: "#",
  },
  {
    title: "Best Recruitment software",
    link: "#",
  },
];
