export const leftLinks = [
  {
    title: "Job Seeker",
    link: "#",
  },
  {
    title: "Podcasts",
    link: "#",
  },
  {
    title: "Recent",
    link: "#",
  },
  {
    title: "Articles",
    link: "#",
  },
  {
    title: "Your Resume",
    link: "#",
  },
  {
    title: "Recruiting",
    link: "#",
  },
  {
    title: "Career Tips",
    link: "#",
  },
  {
    title: "Interviewing",
    link: "#",
  },
  {
    title: "Recruiting",
    link: "#",
  },
  {
    title: "Job Boards",
    link: "#",
  },
  {
    title: "Announcements",
    link: "#",
  },
  {
    title: "Workplace",
    link: "#",
  },
  {
    title: "Linkedin",
    link: "#",
  },
  {
    title: "Job Markets",
    link: "#",
  },
  {
    title: "Jobs",
    link: "#",
  },
  {
    title: "Job Search Notes",
    link: "#",
  },
  {
    title: "Recruitment Marketing",
    link: "#",
  },
  {
    title: "Tech Tools",
    link: "#",
  },
  {
    title: "College Grads",
    link: "#",
  },
  {
    title: "InstaJob",
    link: "#",
  },
  {
    title: "How I Got My Job",
    link: "#",
  },
  {
    title: "Pre-Employment Assessment",
    link: "#",
  },
  {
    title: "In the News",
    link: "#",
  },
  {
    title: "Job Stories",
    link: "#",
  },
  {
    title: "Relocation",
    link: "#",
  },
  {
    title: "Social Recruiting",
    link: "#",
  },
  {
    title: " Testimonials",
    link: "#",
  },

  {
    title: "Tips",
    link: "#",
  },
];
