import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import {
  side,
  searchContainer,
  inputField,
  inputIcon,
  sideLinks,
  sideLinkItem,
  sideImageWrapper,
  remoteJobForm,
} from "./layout.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { leftLinks } from "../linkData/leftBodyLinks";

function LeftBody() {
  return (
    <side className={side}>
      <h3> TELL US WHAT YOU THINK!</h3>
      <p>
        <i>
          Got a question or want us to review something? Shoot us a note at{" "}
          <a href="#">info@careercloud.com </a>and we will do our best to get
          you the info you need.
        </i>
      </p>

      <div className={searchContainer}>
        <FontAwesomeIcon icon={faMagnifyingGlass} className={inputIcon} />
        <input
          className={inputField}
          type="text"
          placeholder="Search"
          name="search"
        />
      </div>

      <div className={sideLinks}>
        {leftLinks.map((item) => (
          <Link to="" className={sideLinkItem}>
            {item.title}
          </Link>
        ))}
      </div>
      <div className={sideImageWrapper}>
        <Link to="" className={sideLinkItem}>
          <StaticImage src="../images/cc_podcast_sidebar.png" alt="podcast" />
        </Link>
      </div>
      <div className={remoteJobForm}>
        <h2>Want a remote job?</h2>
        <form action="">
          <p> Get our free guide with 6 tips to landing a remote job!</p>

          <input type="text" placeholder="First Name" />

          <input type="text" placeholder="Last Name" />

          <input type="email" placeholder="Email Address" />

          <div style={{ display: "flex", justifyContent: "center" }}>
            <input type="submit" value="SEND ME THE TIPS!" />
          </div>
        </form>
        <p>
          <span style={{ fontSize: 12 }}>We respect your privacy.</span>
        </p>
      </div>
    </side>
  );
}

export default LeftBody;
